@font-face {
  font-family: 'LemonMilkLight';
  src: url('LemonMilkLight.otf') format('otf');
}
@font-face {
  font-family: 'LemonMilkRegular';
  src: url('LemonMilkRegular.otf') format('otf'),
       url('LemonMilkRegular.ttf') format('truetype'),
       url('LemonMilkRegular.woff') format('woff');
}
@font-face {
  font-family: 'LemonMilkMedium';
  src: url('LemonMilkMedium.otf') format('otf');
}
@font-face {
  font-family: 'LemonMilkBold';
  src: url('LemonMilkBold.otf') format('otf');
}
