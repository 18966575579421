.login-view {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2em;
}

.login-card {
  border-radius: 0;
  padding: 2em;
  margin: 2em 0;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  width: 30%;
}

.login-card-body {
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  padding: 0;
}

.login-btn {
  margin-top: 2em;
  width: 100%;
}

/* ----------- iPhone 6+, 7+ and 8+ ----------- */
/* Portrait */
@media only screen and (max-device-width: 720px) and (orientation: portrait) {
  .login-card {
    width: 100%;
  }
}
